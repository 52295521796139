@use "./colors" as *;
@use './common_variables' as *;

.btn {
  padding: 12px 16px;
  height: 58px !important;
  align-items: center !important;
  width: 100% !important;
  font-weight: 400;
  outline: 0 transparent solid !important;
  border-radius: 8px;
  font-size: 16px;
  line-height: 24px;
}

.btn-rounded {
  padding: 8px !important;
  height: 85px !important;
  width: 85px !important;
  border-radius: 100% !important;
  align-items: center !important;
  text-transform: uppercase !important;
  font-weight: bold !important;
  outline: 0 transparent solid !important;
}

.btn:focus {
  outline: 0 transparent solid !important;
  box-shadow: none !important;
}

.btn:active {
  outline: 0 transparent solid !important;
  box-shadow: none !important;
}

.btn-default-white-orange {
  padding: 8px;
  height: 28px;
  border-radius: 38px;
  text-transform: uppercase;
  width: 100%;
  font-weight: bold;
  line-height: 8px;
  font-size: 10px;
  color: rgba(232, 78, 39, 0.4);
  border: 1px solid rgba(232, 78, 39, 0.4);
  background-color: #ffffff;
}

.btn:disabled {
  background-color: #dddddd !important;
  color: #f0f0f0;
}

.btn-default-green {
  background-color: #038d18 !important;
  color: white !important;
}

.btn-default-primary {
  background-color: $primary-color !important;
  color: $button-text-color !important;
}

.btn-default-no-bg-border-yellow {
  background-color: transparent !important;
  color: #dd8613 !important;
  border: solid 1px #dd8613 !important;
}

.btn-default-white {
  background-color: $soma-white !important;
  color: #dd8613 !important;
  border: 1px solid #dd8613 !important;
}

.cost-sect {
  background: rgba(237, 186, 172, 0.25) !important;
  border-radius: 17px !important;
  padding: 2px !important;
  text-align: center !important;
}

.cost-sect-concluded {
  background: #e4f2d9 !important;
  border-radius: 17px !important;
  padding: 2px !important;
  text-align: center !important;
}
@use "./colors" as *;

.f-bold {
  font-weight: bold;
}

.fw-700 {
  font-weight: 700;
}

.fw-600 {
  font-weight: 600;
}

.fw-500 {
  font-weight: 500;
}

.fw-400 {
  font-weight: 400;
}

.f-purple {
  color: #6d4976;
}

.f-bluish {
  color: #21344E;
}

.f-purple-dark {
  color: #6c6392;
}

.f-gray-purple {
  color: #5C7089;
}

.f-brown {
  color: #774400;
}

.f-black {
  color: #3d3432;
}

.f-gray-light {
  color: #797371;
}

.f-gray {
  color: #777777 !important;
}

.f-orange {
  color: #e84e27 !important;
}

.f-yellow {
  color: $soma-highlight-yellow !important;
}

.f-green {
  color: #51832a !important;
}

.f-highlightDark {
  color: #774400 !important;
}

.f-highlightLight {
  color: #fff5e9 !important;
}

.f-salmon {
  color: #f3c7ab;
}

.f-blue {
  color: #47a3c5;
}

.f-white {
  color: white;
}

.f-red {
  color: red;
}

.f-link {
  color: $soma-blue !important;
  cursor: pointer;
}

.break-line {
  white-space: pre;
}

.f-under-line {
  text-decoration: underline;
}

.f-2px {
  font-size: 2px !important;
}

.f-4px {
  font-size: 4px !important;
}

.f-6px {
  font-size: 6px !important;
}

.f-8px {
  font-size: 8px !important;
}

.f-10px {
  font-size: 10px !important;
}

.f-12px {
  font-size: 12px !important;
}

.f-14px {
  font-size: 14px !important;
}

.f-16px {
  font-size: 16px !important;
}

.f-18px {
  font-size: 18px !important;
}

.f-20px {
  font-size: 20px !important;
}

.f-22px {
  font-size: 22px !important;
}

.f-24px {
  font-size: 24px !important;
}

.f-26px {
  font-size: 26px !important;
}

.f-28px {
  font-size: 28px !important;
}

.f-30px {
  font-size: 30px !important;
}

.f-32px {
  font-size: 32px !important;
}

.f-34px {
  font-size: 34px !important;
}

.f-36px {
  font-size: 36px !important;
}

.f-40px {
  font-size: 40px !important;
}

.lh-2px {
  line-height: 2px;
}

.lh-4px {
  line-height: 4px;
}

.lh-6px {
  line-height: 6px;
}

.lh-8px {
  line-height: 8px;
}

.lh-10px {
  line-height: 10px;
}

.lh-12px {
  line-height: 12px;
}

.lh-14px {
  line-height: 14px;
}

.lh-16px {
  line-height: 16px;
}

.lh-18px {
  line-height: 18px;
}

.lh-20px {
  line-height: 20px;
}

.lh-22px {
  line-height: 22px;
}

.lh-24px {
  line-height: 24px;
}

.lh-26px {
  line-height: 26px;
}

.lh-28px {
  line-height: 28px;
}

.lh-30px {
  line-height: 30px;
}

.lh-32px {
  line-height: 32px;
}

.lh-34px {
  line-height: 34px;
}

.lh-36px {
  line-height: 36px;
}

.lh-40px {
  line-height: 40px;
}
@use "./colors" as *;
@use './common_variables' as *;

.soma-filed {
  height: 58px;
  padding: 16px;
  border: 1px solid $soma-natural-grey;
  border-radius: 8px;
  background-color: $soma-white !important;
  font-weight: 300;
  display: block;
  width: 100% !important;
}

.soma-filed:focus {
  height: 58px;
  padding: 16px;
  border: 1px solid $primary-color !important;
  border-radius: 8px;
  display: block;
  outline: none !important;
  box-shadow: none !important;
  background-color: $soma-white;
}

.semi-filed {
  height: 58px;
  padding: 16px;
  border: 1px solid #dbdad9;
  background-color: #f2f3f7;
  font-weight: 300;
  border-radius: 8px;
  display: block;
  width: 100%;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-bottom: none !important;
}

.semi-filed:focus {
  height: 58px;
  padding: 16px;
  border: 1px solid #dddddd !important;
  background-color: #f2f3f7;
  font-weight: 300;
  border-radius: 8px;
  display: block;
  width: 100%;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-bottom: none !important;
}

.filed-icon-sect {
  position: absolute;
  right: 20px;
  top: 52.5%;
}

.filed-icon-search {
  position: absolute;
  left: 4px;
  top: 48.5%;
}

.filed-icon-x {
  position: absolute;
  right: 10px;
  top: 48.5%;
}

.form-group>.filed-icon {
  bottom: 44px;
  right: 12px;
  position: relative;
  padding: 0px 5px 0px 5px;
  font-size: 1.1em;
  transition: 0.2s;
  pointer-events: none;
}

.rotateimg180 {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.options-sect {
  position: absolute;
  border: 1px solid #dddddd !important;
  background-color: #f2f3f7;
  font-weight: 300;
  border-radius: 8px;
  width: 91.5%;
  padding-top: 8px;
  padding-bottom: 16px;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-top: none !important;
  max-height: 150px;
  z-index: 99;
  overflow-y: auto !important;
}

.option-row {
  padding-left: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.passowd-field {
  -webkit-text-security: disc;
  -moz-text-security: circle;
}

label {
  font-size: 14px;
  color: $soma-natural-black;
}

.upper-case {
  text-transform: capitalize;
}

.fieldSearch {
  padding: 24px;
}

.fieldSearch:focus {
  padding: 24px;
}
.full-height {
  height: 100vh;
}

.full-height-per {
  height: 100%;
}

.min-full-height {
  min-height: 100vh;
}

.no-scroll-y {
  overflow-y: hidden !important;
}

.no-scroll-x {
  overflow-x: hidden;
}

.app-header {
  display: flex;
  justify-content: space-between;
  padding-top: 64px;
  align-items: center;
  background-color: #ffffff;
}

.cover-bg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.white-bg {
  background-color: #ffffff;
}

.gray-bg {
  background-color: #f5f5f8 !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.view-more {
  display: flex;
  justify-content: space-between;
}

.center {
  text-align: center;
}

.start {
  text-align: start;
}

.justify {
  text-align: justify;
}

.left {
  text-align: left;
}

.justify {
  text-align: justify;
}

.right {
  text-align: right;
}

.pointer-section {
  cursor: pointer;
}

.limit-x-scroll {
  overflow-x: auto !important;
  white-space: nowrap;
}

.scrollable-col {
  display: inline-flex;
  padding: 14px 14px 14px 4px;
  text-decoration: none;
}

.divisor-line {
  border-bottom: 1px solid rgba(204, 204, 204, 0.5);
}

#bottom-modal {
  height: 100vh;
  position: absolute;
  width: 100%;
  z-index: 999;
  top: 0;
  background-color: rgba(49, 49, 49, 0.568);
}

.bottom-modal-clickable-section {
  height: 100%;
}

.bottom-modal {
  background-color: #f4f3f3;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  padding: 0px 24px 21px 24px;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.featured-tag {
  background-color: #faa02b;
  color: #fff;
  position: absolute;
  right: 0;
  top: 0;
  padding: 2px 6px;
  border-radius: 16px;
}

.featured-card-tag {
  background-color: #faa02b;
  color: #fff;
  position: absolute;
  right: 0;
  top: 0;
  width: 80px;
  padding: 2px 6px;
  border-radius: 16px;
  font-size: 12px;
  text-align: center;
}

.out-of-box {
  position: relative;
  padding: 10px;
}

.hide {
  display: none !important;
}

.question-card {
  height: 56px;
  background-color: #f4f3f3;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    width: 1rem;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  padding-top: 48px;
  align-items: center;
}

.align-items-center {
  display: flex;
  align-items: center;
}
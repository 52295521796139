@use './common_variables' as *;

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: $secondary-color;

    .logo {
        margin: auto;
        margin-bottom: 32px;
        display: block;
        width: 115.87px;
        height: 40px;
    }
}

@media only screen and (min-width: 768px) {
    .container {
        .card-form {
            display: flex !important;
            justify-content: center !important;
            flex-direction: column !important;
            align-items: center !important;
            position: absolute !important;
            top: 0 !important;
            bottom: 0 !important;
            left: 0 !important;
            right: 0 !important;
            margin: auto !important;
            background-color: $secondary-color !important;

            p-card {
                align-items: center !important;
                justify-content: center !important;
                display: flex !important;
                width: 484px !important;
                height: 625px !important;
                top: 154px !important;
                left: 478px !important;
                padding: 32px !important;
                gap: 32px !important;
                background-color: #FDFEFF !important;
                border: 1px solid #DDE4EE !important;
                border-radius: 16px !important;
            }
        }

        .text-position {
            text-align: center !important;
        }
    }
}